import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import api from '../middleware/api';
import thunk from 'redux-thunk';

import displayReducer from '../reducers/display';
import {
    bookmarksReducer, notificationsReducer, recentOrdersReducer, remindersReducer,
    notificationCountReducer,
} from '../reducers/header';
import { window } from '../global';

var initialState = typeof(initialState) === 'undefined' ? window.initialState : initialState;

const dropdownReducer = (state = initialState.dropdowns, action) => {
    return state;
};

const identityReducer = (state = initialState.identity, action) => {
    return state;
};

const licenseChangeReducer = (state, action) => {
    return state;
};

const tenantReducer = (state, action) => {
    return state;
};

const entitiesReducer = (state = initialState.entities, action) => {
    const new_state = Object.assign({}, state, {
        license_changes: licenseChangeReducer(state.license_changes, action),
        tenants: tenantReducer(state.tenants, action),
        bookmarks: bookmarksReducer(state.bookmarks, action),
        recent_orders: recentOrdersReducer(state.recent_orders, action),
        reminders: remindersReducer(state.reminders, action),
        notifications: notificationsReducer(state.notifications, action),
        notification_count: notificationCountReducer(
            state.notification_count, action),
    });
    return new_state;
};

export const reducers = {
    display: displayReducer,
    dropdowns: dropdownReducer,
    entities: entitiesReducer,
    identity: identityReducer,
};

export default function configureLicenseChangeStore(initialState) {
    const store = createStore(
        combineReducers(reducers),
        initialState,
        compose(
            applyMiddleware(thunk, api),
            window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
                ? window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
                : f => f
        )
    );

    return store;
}
