import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import ReportTabsContainer from './ReportTabsContainer';
import LabelledSelect from './LabelledSelect';

import { getTenantOptions } from '../selectors/dropdowns';

import { parseMysqlDate } from '../utils';

class LicenseChangeView extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            license_changes: props.license_changes,
            tenant_id: '',
            effective_month: '',
        };

        _.bindAll(this, ['handleChangeFilter', 'handleFilterRows']);
    }

    handleChangeFilter(e, field) {
        this.setState({ [field]: e, loading: true }, function () {
            this.handleFilterRows();
        });
    }

    handleFilterRows() {
        let license_changes = Object.values(this.props.license_changes)
            .filter(l => this.state.tenant_id !== '' ? l.tenant_id === this.state.tenant_id : l.tenant_id === l.tenant_id)
            .filter(l => this.state.effective_month !== '' ? parseMysqlDate(l.date_effective, '0000-00-00', true) === this.state.effective_month : l.date_effective === l.date_effective);

        license_changes = license_changes.reduce((o, l) => { o[l.license_change_id] = l; return o; }, {});

        this.setState({license_changes, loading: false });
    }

    renderLoading() {
        return (
            <div className='row popup-content column'>
                <div className='small-12 columns'>
                    <div className='small-12 text-center'><br /><img src='/images/gears.gif' /><br /></div>
                </div>
            </div>
        );
    }

    renderHeader() {
        const { headerStyle } = style;

        return (
            <tr className="cursor-default">
                <td width="10%" style={headerStyle} className="table-cell-sticky">Tenant</td>
                <td width="10%" style={headerStyle} className="table-cell-sticky">Change</td>
                <td width="10%" style={headerStyle} className="table-cell-sticky"># of Licenses after change</td>
                <td width="10%" style={headerStyle} className="table-cell-sticky">Date of change</td>
                <td width="10%" style={headerStyle} className="table-cell-sticky">Effective month</td>
                <td width="10%" style={headerStyle} className="table-cell-sticky">Changed By</td>
            </tr>
        );
    }

    renderRow(l) {
        return (
            <tr key={l.license_change_id}>
                <td><b><a target="_blank" href={`/distributor.php?id=${l.tenant_id}`} onClick={e => e.stopPropagation()}>{l.tenant_name}</a></b></td>
                <td>{l.change}</td>
                <td>{l.license_count_after}</td>
                <td>{parseMysqlDate(l.date_created)}</td>
                <td>{parseMysqlDate(l.date_effective, '0000-00-00', true)}</td>
                <td>{l.contact_first_name} {l.contact_last_name}</td>
            </tr>
        );
    }

    renderNoRow() {
        return (
            <tr>
                <td colSpan="12">
                    <p>There is no license change record with these search criteria.</p>
                </td>
            </tr>
        );
    }

    render() {
        const { tenant_options, license_changes } = this.props;
        const { summary_style } = style;

        const tenant_dropdowns = [{ key: '', value: 'All' }].concat(tenant_options);

        const effective_month_options = Object.values(license_changes).length > 0 ?
            _.uniq(_.sortBy(Object.values(license_changes), [function (o) { return o.date_effective; }]).map(l => parseMysqlDate(l.date_effective, '0000-00-00', true))).map(d =>
                ({ key: d, value: d })) : [];
        const effective_month_dropdowns = [{ key: '', value: 'All' }].concat(effective_month_options);

        return (
            <div className="main-content" style={{ paddingRight: '12px' }}>
                <ReportTabsContainer page="license_change" csku={true} />
                <div className="row collapse" style={{ maxWidth: 'initial', marginTop: '10px', fontSize: '14px' }}>
                    <LabelledSelect className="small-12 medium-4 columns" placeholder="Tenants" value={this.state.tenant_id} options={tenant_dropdowns} onChange={e => this.handleChangeFilter(e, 'tenant_id')} disabled={this.state.loading} withMarginBottom style={{ marginRight: '8px' }} />
                    <LabelledSelect className="small-12 medium-4 columns" placeholder="Effective Month" value={this.state.effective_month} options={effective_month_dropdowns} onChange={e => this.handleChangeFilter(e, 'effective_month')} disabled={this.state.loading} withMarginBottom />
                    <a target="_blank" className="button small" href="/export_report.php?report_type=license-change&csku_report=true" style={{ float: 'right' }}>Export</a>
                </div>
                {this.state.loading ?
                    this.renderLoading()
                    :
                    <table style={{ marginBottom: 0 }}>
                        <thead style={{ fontSize: '14px' }}>
                            {this.renderHeader()}
                        </thead>
                        <tbody style={{ fontSize: '12px' }}>
                            {Object.values(this.state.license_changes).length > 0 ? Object.values(this.state.license_changes).map(l =>
                                this.renderRow(l)
                            ) :
                                this.renderNoRow()
                            }
                        </tbody>
                    </table>
                }
                <br /><br />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        license_changes: state.entities.license_changes,
        tenant_options: getTenantOptions(state),
    };
};

const mapDispatchToProps = (dispatch, ownProps) => ({});

const style = {
    headerStyle: {
        top: '120px',
        background: '#EDF2F5'
    },
    summary_style: {
        float: 'right',
        margin: '5 10px',
        backgroundColor: '#DFF1FB',
        padding: '5px 20px',
        listStyle: 'none'
    },
};

export default connect(mapStateToProps, mapDispatchToProps)(LicenseChangeView);
