import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getPopups } from '../selectors';
import Header from './Header';
import MainSection from './MainSection';
import Overlay from '../components/Overlay';
import LicenseChangeView from '../components/LicenseChangeView';
import { reducers } from '../store/configureLicenseChangeStore';
import withReducers from '../store/withReducers';

class LicenseChangeApp extends Component {
    render() {
        const { popups } = this.props;

        return (
            <div>
                <Header>
                </Header>
                <MainSection popups={popups}>
                    <LicenseChangeView />
                </MainSection>
                <Overlay popups={popups} />
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => ({
    identity: state.identity,
    popups: getPopups(state)
});

export default withReducers(connect(mapStateToProps)(LicenseChangeApp), reducers);
